import { ReactNode, useEffect, useState } from "react"
import Flex from "../../components/Flex"
import Screen from "./components/Screen"
import { spacing } from "./styles"

import "./Products.scss"
import Button from "./components/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import datasource from "../../datasource"

interface Product {
    icon: React.JSX.Element
    mobileIcon: React.JSX.Element
    title: string
    description: string
    link: string
}

const {
    productData,
    welcomeTitle,
    welcomeSubTitle
} = datasource.products

export default () => {

    const [idx, setIdx] = useState<number>(() => 0)
    const [titleScreenClassName, setTitleScreenClassName] = useState(() => {
        return "mb-products__title-screen"
    })
    const [searhParams] = useSearchParams()
    const navigate = useNavigate()

    const doChangeAni = (_idx: number) => {
        setTitleScreenClassName(["mb-products__title-screen", "mb-products__title-screen_disappear"].join(" "))
        setTimeout(() => {
            setIdx(_idx)
            setTitleScreenClassName(["mb-products__title-screen"].join(" "))
        }, 300);
    }

    const goToCareer = () => {
        navigate("/careers")
        document.scrollingElement?.scrollTo({top: 0, behavior: "smooth"})
    }

    useEffect(() => {
        let idx = 0;
        const param = searhParams.get("idx")
        if(param){
            idx = parseInt(param);
        }else{
            idx = 0
        }
        setIdx(current => {
            // if(current != idx) doChangeAni(idx)
            return idx
        })
        
    }, [searhParams])



    return (
        <div className="mb-products">
            <Screen className={titleScreenClassName}>
                
                <Flex vertical gap={103}>
                    <Flex vertical gap={spacing("4xl")}>
                        <div className="mb-products__title">{productData[idx]["title"]}</div>
                        <div className="mb-products__subTitle">{productData[idx]["subTitle"]}</div>
                    </Flex>
                    <ProductGallery products={productData[idx]["products"]} ></ProductGallery>
                </Flex>
                
            </Screen>
            <Screen className="mb-products__welcome-screen">
                <Flex vertical className="mb-products__welcome" gap={spacing("4xl")}>
                    <Flex vertical gap={10} align="center">
                        <div className="mb-products__welcome__title">{welcomeTitle}</div>
                        <div className="mb-products__welcome__subTitle">{welcomeSubTitle}</div>
                    </Flex>
                    <Button className="mb-products__weclome__btn" onClick={goToCareer}>
                        <Flex justify="center">New Openings</Flex>
                    </Button>
                </Flex>      
            </Screen>              
        </div>
    )
}

const ProductGallery = (props: {products: Product[]}) => {

    return (
        <Flex className="mb-proucts-box" justify="center" gap={spacing("3xl")}>
            {
                props.products.map(it => <Product product={it}></Product>)
            }
        </Flex>
    )
}

const Product = (props: {product: Product}) => {

    const goToLink = () => {
        window.open(props.product.link, "_blank")
    }

    return (
        <Flex 
            vertical 
            align="center" 
            className="mb-product" 
            gap={spacing("3xl")}
            onClick={goToLink} 
        >
            <Flex align="center" justify="center" className="mb-product__icon-box">
                {props.product.mobileIcon}
            </Flex>
            <Flex vertical>
                <div className="mb-product__title">
                    {props.product.title}
                </div>
                <div className="mb-product__description">
                    {props.product.description}
                </div>
            </Flex>
        </Flex>
    )
}