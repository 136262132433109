import "./Screen.scss"

type ScreentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default (props: ScreentProps) => {

    let _props = {
        ...props,
        className: ["screen", props.className??''].join(' ')
    }

    return (
        <div {..._props}>
            {props.children}
        </div>
    )
}