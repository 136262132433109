import { ReactNode, useEffect, useState } from "react"
import Screen from "./components/Screen"
import { spacing } from "./styles"

import "./Products.scss"
import { ProductB2b, ProductGpt, ProductOfferEasy } from "../../components/Icons"
import Button from "./components/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import Flex from "../../components/Flex"

interface Product {
    icon: React.JSX.Element
    title: string
    description: string
    link: string
}

const productData = [
    {
        title: "AI Legal Service",
        subTitle: "Easy Application with AI",
        products: [
            {
                "icon": <ProductB2b width={74} height={74}/>,
                "title": "B2B",
                "description": "Leazy.ai",
                "link": "https://www.leazy.ai/"
            },
            {
                "icon": <ProductGpt width={108} height={108}/>,
                "title": "GPTS APP",
                "description": "Veazy Immigration Assistant",
                "link": "https://chat.openai.com/g/g-Urj8APngM-veazy-immigration-assistant"
            }
        ] as Product[]
    },
    {
        title: "AI School Application Service",
        subTitle: "Easy Application with AI",
        products: [
            {
                "icon": <ProductOfferEasy width={108} height={108}/>,
                "title": "GPTS APP",
                "description": "OfferEazy",
                "link": "https://chat.openai.com/g/g-wZXDzMeh4-offereazy"
            }
        ] as Product[]
    }
]
const welcomeTitle = "Let's work with us";
const welcomeSubTitle = "Check our new openings.";

export default () => {

    const [idx, setIdx] = useState<number>(() => 0)
    const [titleScreenClassName, setTitleScreenClassName] = useState(() => {
        return "products__title-screen"
    })
    const [searhParams] = useSearchParams()
    const navigate = useNavigate()

    const doChangeAni = (_idx: number) => {
        setTitleScreenClassName(["products__title-screen", "products__title-screen_disappear"].join(" "))
        setTimeout(() => {
            setIdx(_idx)
            setTitleScreenClassName(["products__title-screen"].join(" "))
        }, 300);
    }

    useEffect(() => {
        console.log(`searchParams changed: `, searhParams.get("idx"))
        let idx = 0;
        const param = searhParams.get("idx")
        if(param){
            idx = parseInt(param);
        }else{
            idx = 0
        }
        setIdx(current => {
            // if(current != idx) doChangeAni(idx)
            return idx
        })
        
    }, [searhParams])

    const goToCareer = () => {
        navigate("/careers")
        document.scrollingElement?.scrollTo({top: 0, behavior: "smooth"})
    }


    return (
        <div className="products">
            <Screen className={titleScreenClassName}>
                
                <Flex vertical gap={103}>
                    <Flex vertical gap={spacing("4xl")}>
                        <div className="products__title">{productData[idx]["title"]}</div>
                        <div className="products__subTitle">{productData[idx]["subTitle"]}</div>
                    </Flex>
                    <ProductGallery products={productData[idx]["products"]} ></ProductGallery>
                </Flex>
                
            </Screen>
            <div className="products__welcome-box">
                <Flex className="products__welcome" justify="space-between">
                    <Flex vertical gap={10}>
                        <div className="products__welcome__title">{welcomeTitle}</div>
                        <div className="products__welcome__subTitle">{welcomeSubTitle}</div>
                    </Flex>
                    <Flex vertical justify="flex-end">
                        <Button onClick={() => goToCareer()}>New Openings</Button>
                    </Flex>
                </Flex>      
            </div>              
        </div>
    )
}

const ProductGallery = (props: {products: Product[]}) => {

    return (
        <Flex className="proucts-box" justify="center" >
            {
                props.products.map(it => <Product product={it}></Product>)
            }
        </Flex>
    )
}

const Product = (props: {product: Product}) => {

    const goToLink = () => {
        window.open(props.product.link, "_blank")
    }

    const [hover, setHover] = useState<boolean>()

    return (
        <Flex 
            vertical 
            align="center" 
            justify="center" 
            className="product"  
            onClick={goToLink} 
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            >
            <Flex align="center" justify="center" className="product__icon-box">
                {props.product.icon}
            </Flex>
            <Flex className="product__highlight-box" justify="center" align="center">
                <div className={["product__highlight", hover?"product__highlight_hover":""].join(" ")}></div>
            </Flex>
            <Flex vertical>
                <div className="product__title">
                    {props.product.title}
                </div>
                <div className="product__description">
                    {props.product.description}
                </div>
            </Flex>
        </Flex>
    )
}