import Flex from "../../components/Flex"
import Screen from "./components/Screen"

import "./Career.scss"
import { spacing } from "./styles"
import { Spec } from "../../components/Icons"
import datasource from "../../datasource"

const {
    title,
    subTitle,
    jobboxTitle,
    joboxSubTitle,
    jobs
} = datasource.careers


export default () => {

    return (
        <div className="mb-career">
            <Screen className="mb-career__title-box">
                <Flex vertical align="center" gap={16}>
                    <div className="mb-career__title">{title}</div>
                    <div className="mb-career__subTitle">{subTitle}</div>
                </Flex>
            </Screen>
            <Screen className="mb-job-box-screen">
                <div className="mb-job-box">
                    <Flex vertical gap={spacing("4xl")}>
                        <Flex vertical gap={10}>
                            <div className="mb-job-box__title">{jobboxTitle}</div>
                            <div className="mb-job-box__subTitle">{joboxSubTitle}</div>
                        </Flex>
                        <Flex vertical gap={spacing("4xl")} align="center">
                            <Job idx={0}></Job>
                            <Job idx={1}></Job>
                            <Job idx={2}></Job>
                        </Flex>
                    </Flex>
                </div>
            </Screen>
        </div>
    )
}


const Job = (props: {idx: number}) => {

    const {
        idx
    } = props

    return (
        <Flex vertical className="mb-job" gap={16}>
            <Flex>
                <div className="mb-job__title">{jobs[idx]['title']}</div>
            </Flex>
            <Flex vertical className="mb-job__content" gap={30}>
                <div className="mb-job__content__icon">
                    <Spec idx={idx} width={37} height={34}></Spec>
                </div>
                <div className="mb-job__content__description">
                    {jobs[idx]['description']}
                </div>
            </Flex>
        </Flex>
    )
}