import { useContext, useEffect, useState } from "react";
import Flex from "../../../components/Flex"

import "./layout.scss";
import { MenuContext } from "../../../contexts/MenuContext";
import Icons from "../../../components/Icons";
import { spacing } from "../styles";
import { Link, Location} from "react-router-dom";
import useNav from "../../../components/useNav";

interface MenuItemType {
    idx: number
    title: string
    path: string 
    children?: MenuItemType[]
}

const menuData:MenuItemType[] = [
    {
        "idx": 0,
        "title": "Company",
        "path": "/"
    },
    {
        "idx": 1,
        "title": "Products",
        "path": "/products",
        "children": [
            {
                "idx": 0,
                "title": "AI Legal Service",
                "path": "/products?idx=0"
            },
            {
                "idx": 1,
                "title": "AI School Application Service",
                "path": "/products?idx=1"
            }
        ]
    },
    {
        "idx": 2,
        "path": "/careers",
        "title": "Careers",
    }
]

export default () => {

    return (
        <Flex className="layout__header-box" align="center" justify="center">
            <Flex className="layout__header" justify="center" align="center">
                <Menu></Menu>
            </Flex>
            <Logo/>
        </Flex>
    )
}

const Logo = () => {

    const {nav} = useNav();

    return (
        <Flex className="layout__header__logo" align="center">
            <Icons.Logo onClick={() => nav("/")}></Icons.Logo>
        </Flex>
    )
}

const Menu = () => {
    return (
        <Flex gap={40} align="center">
            {
                menuData.map(it => <MenuItem item={it}></MenuItem>)
            }
        </Flex>
    )
}

const MenuItem = (props: {item: MenuItemType}) => {

    const {
        item
    } = props
    const {current, setCurrent} = useContext(MenuContext)
    const {nav} = useNav();
    const {render: subMenuList, open, close} = useMenuList(item.idx, item.children)

    const doClick = () => {
        if(!item.children){
            setCurrent([item.idx])
            nav(item.path)
        }
    }

    const doHover = () => {
        item.children && open()
    }

    const undoHover = () => {
        item.children && close()
    }

    return (
        <div 
            className={["menu__item", current[0] == item.idx?"menu__item_activate":""].join(" ")} 
            onClick={doClick}
            onMouseEnter={doHover}
            onMouseLeave={undoHover}
        >
            {/* <a href={item.path}>{item.title}</a> */}
            {item.title}
            {item.children && subMenuList}
        </div>
    )
}

const useMenuList = (parentIdx: number, items?: MenuItemType[]) => {

    const [show, setShow] = useState<boolean>(() => false);

    const render = (
        <div className={"menu-list-box"}>
            <div className={['menu-list', show?'menu-list_activate':''].join(" ")}>
                <Flex vertical gap={spacing("md")}>
                    {items?.map(it => <MenuListItem parentIdx={parentIdx} item={it}></MenuListItem>)}
                </Flex>
            </div>
        </div>
    )

    const open = () => {
        setShow(true)
    }

    const close = () => {
        setShow(false)
    }

    return {
        render,
        open,
        close
    }
}

const MenuListItem = (props: {parentIdx: number, item: MenuItemType}) => {

    const {current, setCurrent} = useContext(MenuContext)
    const {nav} = useNav();

    const {
        parentIdx,
        item
    } = props

    const doClick = () => {
        // console.log("menuListItem doClick: ", parentIdx, item.idx)
        setCurrent([parentIdx, item.idx])
        nav(item.path)
        //TODO
        // document.scrollingElement?.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <div 
        className={["menu-list__item", current[1] == item.idx?"menu-list__item_activate":''].join(" ")}
        onClick={doClick}
        >
            {item.title}
        
        </div>
    )
}


export function calCurrentMenu(location: Location){

    const path = location.pathname
    switch(path){
        case "/products":
            const search = location.search
            if(search.endsWith("1")){
                return [1, 1]
            }
            return [1, 0]
        case "/careers":
            return [2]
        default:
            return [0]

    }

}