import { useNavigate } from "react-router-dom"



export default () => {

    const navigate = useNavigate();

    return {
        nav(to: string){
            navigate(to)
            document.scrollingElement?.scrollTo({top: 0, behavior: "smooth"})
        }
    }
}