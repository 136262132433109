

import arrowUpSvg from "../assets/images/arrowup.svg"
import specSvg0 from "../assets/images/spec1.svg"
import specSvg1 from "../assets/images/spec2.svg"
import specSvg2 from "../assets/images/spec3.svg"
import productB2b from "../assets/images/products_b2b.svg"
import productGpt from "../assets/images/products_gpt.svg"
import productOfferEasy from "../assets/images/products_offereasy.svg"
import logo from "../assets/images/logo.svg"
import logoDark from "../assets/images/logo_dark.svg"
import instagram from "../assets/images/instagram.svg"
import linkedin from "../assets/images/linkedin.svg"
import whatsapp from "../assets/images/whatsapp.svg"
import twitter from "../assets/images/twitter.svg"
import mobileLogo from "../assets/images/logo_mobile.svg"
import mobileMenuBtnClosed from "../assets/images/mobile_menu_btn_closed@3x.png"
import mobileMenuBtnOpened from "../assets/images/mobile_menu_btn_opened@3x.png"
import chevronDown from "../assets/images/chevron_down.svg"
import chevronUp from "../assets/images/chevron_up.svg"
import success from "../assets/images/success.svg"
import notificationClose from "../assets/images/notification_close.svg"
import fail from "../assets/images/fail.svg";

import { CSSProperties } from "react"

type IconSize = {
    width?: number
    height?: number
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

type IconProps = {
    src: string
} & IconSize 

export const ArrowUp = (props: IconSize) => {
    return (<Icon src={arrowUpSvg} {...props}></Icon>)
}

export const Spec = (props: {idx: number} & IconSize) => {
    return props.idx==0?<Spec0 {...props}/>:props.idx==1?<Spec1 {...props}/>:<Spec2 {...props}/>
}

export const Spec0 = (props: IconSize) => {
    return (<Icon src={specSvg0} {...props}></Icon>)
}

export const Spec1 = (props: IconSize) => {
    return (<Icon src={specSvg1} {...props}></Icon>)
}

export const Spec2 = (props: IconSize) => {
    return (<Icon src={specSvg2} {...props}></Icon>)
}

export const ProductB2b = (props: IconSize) => {
    return (<Icon src={productB2b} {...props}></Icon>)
}

export const ProductGpt = (props: IconSize) => {
    return (<Icon src={productGpt} {...props}></Icon>)
}

export const ProductOfferEasy = (props: IconSize) => {
    return (<Icon src={productOfferEasy} {...props}></Icon>)
}

export const Logo = (props: IconSize) => {
    return (<Icon src={logo} {...props}></Icon>)
}

export const LogoDark = (props: IconSize) => {
    return (<Icon src={logoDark} {...props}></Icon>)
}

export const Instagram = (props: IconSize) => {
    return (<Icon src={instagram} {...props}></Icon>)
}

export const Whatsapp = (props: IconSize) => {
    return (<Icon src={whatsapp} {...props}></Icon>)
}

export const Linkedin = (props: IconSize) => {
    return (<Icon src={linkedin} {...props}></Icon>)
}

export const Twitter = (props: IconSize) => {
    return (<Icon src={twitter} {...props}></Icon>)
}

export const MobileLogo = (props: IconSize) => {
    return (<Icon src={mobileLogo} {...props}></Icon>)
}

export const MobileMenuBtnClosed = (props: IconSize) => {
    return (<Icon src={mobileMenuBtnClosed} {...props}></Icon>)
}

export const MobileMenuBtnOpened = (props: IconSize) => {
    return (<Icon src={mobileMenuBtnOpened} {...props}></Icon>)
}

export const ChevronDown = (props: IconSize) => {
    return (<Icon src={chevronDown} {...props}></Icon>)
}

export const ChevronUp = (props: IconSize) => {
    return (<Icon src={chevronUp} {...props}></Icon>)
}

export const Success = (props: IconSize) => {
    return (<Icon src={success} {...props}></Icon>)
}

export const NotificationClose = (props: IconSize) => {
    return (<Icon src={notificationClose} {...props}></Icon>)
}

export const Fail = (props: IconSize) => {
    return (<Icon src={fail} {...props}></Icon>)
}

export const Icon = (props: IconProps) => {

    // console.log(`Icon props: ${JSON.stringify(props)}`)

    const styles: CSSProperties = {
        width: props.width + "px",
        height: props.height + "px"
    }

    return (<img {...props} src={props.src} style={styles}></img>)

}

export default {
    ArrowUp,
    Spec,
    ProductB2b,
    ProductGpt,
    ProductOfferEasy,
    Logo,
    LogoDark,
    Instagram,
    Whatsapp,
    Linkedin,
    Twitter,
    MobileLogo,
    MobileMenuBtnClosed,
    MobileMenuBtnOpened,
    ChevronDown,
    ChevronUp,
    Success,
    NotificationClose,
    Fail
}