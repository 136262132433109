import { ProductB2b, ProductGpt, ProductOfferEasy } from "./components/Icons";
import MobileIcon from "./components/MobileIcon";



export default {
    menuData: [
        {
            "idx": 0,
            "title": "Company",
            "path": "/"
        },
        {
            "idx": 1,
            "title": "Products",
            "path": "/products",
            "children": [
                {
                    "idx": 0,
                    "title": "AI Legal Service",
                    "path": "/products?idx=0"
                },
                {
                    "idx": 1,
                    "title": "AI School Application Service",
                    "path": "/products?idx=1"
                }
            ]
        },
        {
            "idx": 2,
            "path": "/careers",
            "title": "Careers",
        }
    ],
    home: {
        title:["Navigating the Future of","Advanced Technologies"],
        subTitle: "Easy Application with AI",
        contactUsBtnLabel: "Contact us ",
        contactFormTitle: 'CONTACT',
        contactFormSubtitle: ['San Francisco Bay Area, CA 95014', 'info@roundblock.io']
    },
    products: {
        productData: [
            {
                title: "AI Legal Service",
                subTitle: "Easy Application with AI",
                products: [
                    {
                        "icon": <ProductB2b width={74} height={74}/>,
                        "mobileIcon": <MobileIcon.ProductB2b width={46} height={46}/>,
                        "title": "B2B",
                        "description": "Leazy.ai",
                        "link": "https://www.leazy.ai/"
                    },
                    {
                        "icon": <ProductGpt width={108} height={108}/>,
                        "mobileIcon": <MobileIcon.ProductGpt width={64} height={64}/>,
                        "title": "GPTS APP",
                        "description": "Veazy Immigration Assistant",
                        "link": "https://chat.openai.com/g/g-Urj8APngM-veazy-immigration-assistant"
                    }
                ]
            },
            {
                title: "AI School Application Service",
                subTitle: "Easy Application with AI",
                products: [
                    {
                        "icon": <ProductOfferEasy width={108} height={108}/>,
                        "mobileIcon": <MobileIcon.ProductOfferEasy width={64} height={64}/>,
                        "title": "GPTS APP",
                        "description": "OfferEazy",
                        "link": "https://chat.openai.com/g/g-wZXDzMeh4-offereazy"
                    }
                ]
            }
        ],
        welcomeTitle: "Let's work with us",
        welcomeSubTitle: "Check our new openings."
    },
    careers: {
        title: "JOIN US!",
        subTitle: "We Have Following Openings",
        jobboxTitle: "Forward Together",
        joboxSubTitle: "Please send your resume to hiring@roundblock.io for applications.",
        jobs: [
            {
                title: "Front End Engineer",
                description: "You'll be responsible for developing responsive, robust, and visually striking interfaces that engage and delight users."
            },
            {
                title: "UI/UX Intern",
                description: "This role offers a unique opportunity to shape the user experience from the ground up, all while learning from industry experts in a dynamic, fast-paced environment."
            },
            {
                title: "AI Research Scientist",
                description: "A strong candidate will have a deep understanding of machine learning, neural networks, and computational statistics, with a passion for innovation and a track record of published research"
            }
        ]
    },
    footer: {
        company: {
            title: "© 2024 Roundblock Technology LLC. All rights reserved."
        },
        products: {
            title: "Products",
            items: [
                {
                    "title":"Leazy.Ai",
                    "link": "https://www.leazy.ai/"
                },
                {
                    "title":"Veazy",
                    "link": "https://chat.openai.com/g/g-Urj8APngM-veazy-immigration-assistant"
                },
                {
                    "title":"OfferEazy",
                    "link": "https://chat.openai.com/g/g-wZXDzMeh4-offereazy"
                }
            ]
        },
        contactus: {
            title: "Contact us",
            items: [
                "info@roundblock.io"
            ]
        },
        address: {
            title: "Address",
            items: [
                "3400 Cottage Way, Ste G2  #14254",
                "sacramento, California, 95825"
            ]
        }
    },
    contactUs: {
        success: "Your information has been submitted successfully.",
        fail: "Please try again."
    }   
}