
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import "./HomePage.scss"
import Page from "./components/Page"
import Screen from "./components/Screen"
import Button from "./components/Button"
import Icons from "../../components/Icons"
import Flex from "../../components/Flex"
import { spacing } from "./styles"
import { FormContext, FormContextType, FormItemContext } from "../../contexts/FormContext"
import datasource from "../../datasource"
import leazyAiCrmApi, { InquiriesRequest } from "../../api/leazyAiCrm.api"
import useNotification from "./components/useNotification"
import regex from "../../regex"

const {
    title,
    subTitle,
    contactFormSubtitle,
    contactFormTitle,
    contactUsBtnLabel
} = datasource["home"]

export default () => {

    const form = useFormInstance()
    const [disabled, setDisabled] = useState<boolean>(() => false)
    const {success, error, render: notificationRender} = useNotification()
    const [loading, setLoading] = useState<boolean>(() => false);

    useEffect(() => {
        
    }, [])

    useEffect(() => {
        setDisabled(form.finish?false:true)
    }, [form.finish])

    const scrollToContactUsForm = () => {
        document.scrollingElement?.scrollTo({top: window.innerHeight, behavior: "smooth"})
    }

    const doSubmit = () => {
        if(!disabled && !loading){
            setLoading(true)
            const request = form.data as InquiriesRequest
            leazyAiCrmApi.inquiries(request).then(() => {
                success(datasource.contactUs.success)
                form.clear()
            }).catch(() => error(datasource.contactUs.fail)).finally(() => setLoading(false))
        }
    }

    return (
        <div className="home">
            <Screen className="title-screen">
                <Flex vertical align="center" gap={150} className="title-box">
                    <Flex vertical gap={spacing("4xl")}>
                        <Flex vertical>
                            <div className="title__h1">{title[0]}</div>
                            <div className="title__h1 title__h1_gradient">{title[1]}</div>
                        </Flex>
                        <Flex justify="center">
                            <div className="title__h2">{subTitle}</div>
                        </Flex>
                    </Flex>
                    <Flex justify="center">
                        <Button onClick={scrollToContactUsForm}>
                            <Flex gap={spacing("md")} reposive={false}>{contactUsBtnLabel}<Icons.ArrowUp></Icons.ArrowUp></Flex>
                        </Button>
                    </Flex>
                </Flex>
            </Screen>
            <Screen className="contactus-screen">
                <Flex className="contactus-box" justify="space-between">
                    <Flex className="contactus__header" vertical gap={10}>
                        <div className="contactus__header__title">{contactFormTitle}</div>
                        <div className="contactus__header__subTitle">{contactFormSubtitle[0]}</div>
                        <div className="contactus__header__subTitle">{contactFormSubtitle[1]}</div>
                    </Flex>
                    <Form form={form}>
                        <Flex vertical gap={56}>
                            <Flex vertical gap={spacing("2xl")}>
                                <Flex justify="space-between">
                                    <FormItem 
                                        name="name" 
                                        label="Name" 
                                        required
                                        rules={[
                                            {accept: (value) => !!value && "" != value, msg: "", show: false}
                                        ]}
                                    >
                                        <Input />
                                    </FormItem>
                                    <FormItem 
                                        name="email" 
                                        label="Email" 
                                        required 
                                        rules={[
                                            {accept: (value) => !value || regex.email.test(value), msg: "Please enter the correct email address", show: true},
                                            {accept: (value) => !!value && "" != value, msg: "", show: false}
                                        ]}>
                                        <Input/>
                                    </FormItem>
                                </Flex>
                                <Flex justify="space-between">
                                    <FormItem name="phone" label="Phone" >
                                        <Input/>
                                    </FormItem>
                                    <FormItem name="address" label="Address" >
                                        <Input/>
                                    </FormItem>
                                </Flex>
                                <Flex justify="space-between">
                                    <FormItem 
                                        name="subject" 
                                        label="Subject" 
                                        required
                                        rules={[
                                            {accept: (value) => !!value && "" != value, msg: "", show: false}
                                        ]}
                                        >
                                        <Input/>
                                    </FormItem>
                                </Flex>
                                <Flex>
                                    <FormItem name="message" label="Message" >
                                        <Textarea></Textarea>
                                    </FormItem>
                                </Flex>
                            </Flex>
                            <Flex justify={"flex-end"}>
                                <Button disabled={disabled} onClick={doSubmit}>Submit</Button>
                            </Flex>
                        </Flex>
                    </Form>
                </Flex>
            </Screen>
            {notificationRender}
        </div>
    )

}

interface FormInstance extends FormContextType {
    finish: boolean,
    data: Record<string, any>
    
}

const useFormInstance = (): FormInstance => {

    const [finish, setFinish] = useState<boolean>(() => false)
    const [data, setData] = useState<Record<string, any>>(() => {});
    const [error, setError] = useState<Record<string, any>>(() => {});


    useEffect(() => {

        if(!error || Object.keys(error).length == 0){
            // console.log(`form error changed finish: ${JSON.stringify(error)}`)
            setFinish(true)
        }else{
            // console.log(`form error changed unfinish: ${JSON.stringify(error)}`)
            setFinish(false)
        }
    }, [error])

    return {
        data,
        error,
        finish,
        setFieldValue(name: string, value: any){
            setData({...data, [name]: value})
        },
        setFieldError(fieldName, msg) {
            // console.log(`setFieldError. fieldName:${fieldName}, msg: ${msg}`)
            setError((current) => {
                const _error = {...current, [fieldName]: msg};
                return _error
            })
        },
        setFieldSuccess(fieldName) {
            // console.log(`setFieldSuccess. fieldName:${fieldName}`)
            setError((current) => {
                const _error = {...current}
                delete _error[fieldName]
                return _error;
            })
        },
        clear() {
            setData({})
        },
    }
}

const Form = (props: PropsWithChildren<{form: FormInstance}>) => {

    const {
        form
    } = props

    return (
        <FormContext.Provider value={form}>
            <div>
                {props.children}
            </div>
        </FormContext.Provider>
        
    )
}

export interface FormItemRuleType {
    accept: (value: string) => boolean
    msg?: string
    show?: boolean
}

export interface FormItemType {
    label: string
    required?: boolean
    name: string
    rules?: FormItemRuleType[]
}
const FormItem = (props: PropsWithChildren<FormItemType>) => {

    const {setFieldValue, setFieldError, setFieldSuccess, data = {}, error = {}} = useContext(FormContext)

    const _setFieldValue = (value: any) => {

        if(props.rules){
            const rule = props.rules.find(rule => !rule.accept(value))
            if(rule){
                setFieldError(props.name, {msg: rule.msg, show: rule.show})
            }else{
                setFieldSuccess(props.name)
            }
        }else{
            setFieldSuccess(props.name)
        }
        // if(props.required && (value == null || value == "")){
        //     setFieldError(props.name, "")
        // }else{
        //     setFieldSuccess(props.name)
        // }
        setFieldValue(props.name, value)
    }

    return (
        <FormItemContext.Provider value={{name: props.name, setFieldValue: _setFieldValue, fieldValue: data[props.name], fieldError: error[props.name]}}>
            <Flex vertical gap={spacing("xs")}>
                <div className={["contactus__form__item__label", error[props.name]?.['show']?"contactus__form__item__label_error":""].join(" ")}>{props.label}{props.required?" * ":""}</div>
                <div className="contactus__form__item__content">
                    {props.children}
                </div>
                <div className="contactus__form__item__error">{error[props.name]?.["msg"]??undefined}</div>
            </Flex>
        </FormItemContext.Provider>
    )
}


const Input = (props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {

    // const {setFieldValue} = useContext(FormContext)
    const {setFieldValue, fieldValue = "", fieldError} = useContext(FormItemContext)
    const [tmp, setTmp] = useState<string>(() => fieldValue)
    const [inputing, setInputing] = useState<boolean>()

    useEffect(() => {
        setFieldValue(undefined)
    }, [])

    useEffect(() => {
        setTmp(fieldValue)
    }, [fieldValue])

    const startChange = () => {
        setInputing(true)
    }

    const submitChange = () => {
        setInputing(false)
        setFieldValue(tmp)
    }

    const onChange = (value: string) => {
        setTmp(value)
        if(!inputing){
            setFieldValue(tmp)
        }
    }

    return (
        <input 
            className={["contactus__form__input", fieldError?.['show']?"contactus__form__input_error":""].join(" ")}
            {...props}
            onFocus={() => startChange()}
            onBlur={e => submitChange()}
            onChange={e => onChange(e.target.value)}
            value={tmp}
        ></input>
    )
}

const Textarea = (props: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>) => {

    const {setFieldValue, fieldValue = "", fieldError} = useContext(FormItemContext)
    const [tmp, setTmp] = useState<string>(() => fieldValue)
    const [inputing, setInputing] = useState<boolean>()

    useEffect(() => {
        setFieldValue(undefined)
    }, [])

    useEffect(() => {
        setTmp(fieldValue)
    }, [fieldValue])

    const startChange = () => {
        setInputing(true)
    }

    const submitChange = () => {
        setInputing(false)
        setFieldValue(tmp)
    }

    const onChange = (value: string) => {
        setTmp(value)
        if(!inputing){
            setFieldValue(tmp)
        }
    }

    return (
        <textarea 
            className={["contactus__form__textarea", fieldError?.['show']?"contactus__form__textarea_error":""].join(" ")}
            {...props}
            onFocus={() => startChange()}
            onBlur={e => submitChange()}
            onChange={e => onChange(e.target.value)}
            value={tmp}
        ></textarea>
    )
}