
import { CSSProperties, useState } from "react"

export type JustifyOptions = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
export type AlignOptions = 'flex-start' | 'center' | 'flex-end'

export type FlexType = {
    vertical?: boolean
    gap?: number | string,
    justify?: JustifyOptions
    align?: AlignOptions
    reposive?: boolean

} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default (props: FlexType) => {
    
    const {
        vertical = false,
        gap,
        justify,
        align,
        reposive = true
    } = props

    const [styles] = useState<CSSProperties>(() => {

        const _styles:CSSProperties = {
            display: "flex"
        }
        if(vertical){
            _styles["flexDirection"] = "column"
        }
        if(justify){
            _styles["justifyContent"] = justify
        }
        if(align){
            _styles["alignItems"] = align
        }
        
        if(gap){
            if(reposive){
                if(vertical){
                    _styles["gap"] = `calc(${gap}/900 * 100vh)`
                }else{
                    _styles["gap"] = `calc(${gap} * var(--screen-ratio, 1) * 1px)`
                }
            }else{
                _styles["gap"] = `calc(${gap} * 1px)`
            }
        }

        return {..._styles, ...props.style??{}}

    })


    return (
        <div {...props} style={styles}>
            {props.children}
        </div>
    )
}