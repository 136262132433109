import { useContext, useEffect, useState } from "react";
import Flex from "../../../components/Flex"

import "./layout.scss";
import { MenuContext } from "../../../contexts/MenuContext";
import { spacing } from "../styles";
import { Link, Location} from "react-router-dom";
import datasource from "../../../datasource";
import MobileIcon from "../../../components/MobileIcon";
import useNav from "../../../components/useNav";

interface MenuItemType {
    idx: number
    title: string
    path: string 
    children?: MenuItemType[]
}

const menuData:MenuItemType[] = datasource.menuData


export default () => {

    const [open, setOpen] = useState<boolean>(() => false)
    const {render, open: openMenu} = useMenu()
    const {current, setCurrent} = useContext(MenuContext)


    const toggleOpen = () => {
        setOpen(_open => !_open)
    }

    useEffect(() => {
        openMenu(open)
    }, [open])

    useEffect(() => {
        setOpen(false)
    }, [current])

    return (
        <div className={["mb-layout__header-box", open?"mb-layout__header-box_activate":""].join(" ")}>
            <Flex className={["mb-layout__header", open?"mb-layout__header_activate":""].join(" ")} justify="space-between" align="center">
                <Logo></Logo>
                {
                    open
                    ?
                    <MobileIcon.MobileMenuBtnOpened width={24} height={24} onClick={toggleOpen}/>
                    :
                    <MobileIcon.MobileMenuBtnClosed width={32} height={32} onClick={toggleOpen}/>
                }
            </Flex>
            {render}
        </div>
    )
}

const Logo = () => {

    const {nav} = useNav();

    return (
        <Flex className="mb-layout__header__logo" align="center">
            <Flex gap={spacing("md")} align="center">
                <MobileIcon.MobileLogo width={32} height={32} onClick={() => nav("/")}></MobileIcon.MobileLogo>
                Roundblock
            </Flex>
            
        </Flex>
    )
}

const useMenu = () => {

    const [show, setShow] = useState<boolean>()
    const [disapear, setDisapear] = useState<boolean>()
    

    const render = (
        <div className={["mb-menu", show?"mb-menu_activate":"", disapear?"mb-menu_disapear":""].join(" ")}>
            <Flex gap={spacing("md")} vertical>
                {
                    menuData.map(it => <MenuItem item={it}></MenuItem>)
                }
            </Flex>
        </div>
    )

    const open = (flag: boolean) => {
        if(!flag){
            setTimeout(() => {
                setDisapear(true)
            }, 300);
        }else{
            setDisapear(false)
        }
        setShow(flag)
    }

    return {
        render,
        open
    }
}

const MenuItem = (props: {item: MenuItemType}) => {

    const {
        item
    } = props
    const {current, setCurrent} = useContext(MenuContext)
    const {nav} = useNav();
    const {render: subMenuList, open: openSub, close: closeSub} = useSubMenuList(item.idx, item.children)
    const [open, setOpen] = useState<boolean>()

    const doClick = () => {
        if(!item.children){
            setCurrent([item.idx])
            nav(item.path)
            //TODO
            document.scrollingElement?.scrollTo({top: 0, behavior: "smooth"})
        }else{
            setOpen(_open => {
                return !_open;
            })
        }
    }

    useEffect(() => {
        open ? openSub() : closeSub()
    }, [open])

    return (
        <>
            <Flex 
                className={["mb-menu__item", (!item.children && current[0] == item.idx)?"mb-menu__item_activate":""].join(" ")} 
                onClick={doClick}
                gap={16} 
                justify="space-between" 
                align="center"
            >
                {/* <a href={item.path}>{item.title}</a> */}
                <div>{item.title}</div>
                {item.children && (open?<MobileIcon.ChevronUp width={20} height={20}/>:<MobileIcon.ChevronDown width={20} height={20}/>)}
            </Flex>
            {item.children && subMenuList}
        </>
    )
}

const useSubMenuList = (parentIdx: number, items?: MenuItemType[]) => {

    const [show, setShow] = useState<boolean>(() => false);

    const render = (
        <div className={"mb-menu-list-box"}>
            <div className={['mb-menu-list', show?'mb-menu-list_activate':''].join(" ")}>
                <Flex vertical gap={spacing("md")}>
                    {items?.map(it => <MenuListItem parentIdx={parentIdx} item={it}></MenuListItem>)}
                </Flex>
            </div>
        </div>
    )

    const open = () => {
        setShow(true)
    }

    const close = () => {
        setShow(false)
    }

    return {
        render,
        open,
        close
    }
}

const MenuListItem = (props: {parentIdx: number, item: MenuItemType}) => {

    const {current, setCurrent} = useContext(MenuContext)
    const {nav} = useNav();

    const {
        parentIdx,
        item
    } = props

    const doClick = () => {
        // console.log("menuListItem doClick: ", parentIdx, item.idx)
        setCurrent([parentIdx, item.idx])
        nav(item.path)
        //TODO
        // document.scrollingElement?.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <div 
        className={["mb-menu-list__item", current[1] == item.idx?"mb-menu-list__item_activate":''].join(" ")}
        onClick={doClick}
        >
            {item.title}
        
        </div>
    )
}


export function calCurrentMenu(location: Location){

    const path = location.pathname
    switch(path){
        case "/products":
            const search = location.search
            if(search.endsWith("1")){
                return [1, 1]
            }
            return [1, 0]
        case "/careers":
            return [2]
        default:
            return [0]

    }

}