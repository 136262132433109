import { ReactNode } from "react"

import "./Button.scss"

type ButtonProps = {
    
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default (props: ButtonProps) => {


    return (
        <div className="rb-inline-block">
            <div className={["rb-button", props.disabled?'rb-button_disabled':''].join(" ")} {...props}>
                {props.children}
            </div>
        </div>
        
    )
}