import datasource from "../../../datasource"
import Flex from "../../../components/Flex"
import Icons, { Icon } from "../../../components/Icons"
import { spacing } from "../styles"
import useNav from "../../../components/useNav"


const {
    company,
    products,
    contactus,
    address
} = datasource.footer


export default () => {

    const {nav} = useNav();

    return (
        <div className="layout__footer">
            <Flex vertical gap={80}>
                <Flex gap={364} justify='space-between'>
                    <div className="footer__logo">
                        <Icons.LogoDark onClick={() => nav("/")}></Icons.LogoDark>
                    </div>
                    <Flex gap={106}>
                        <Product></Product>
                        <ContactUs></ContactUs>
                        <Address></Address>
                    </Flex>
                </Flex>
                <Flex className="footer__company" justify="space-between">
                    {company.title}
                    <Flex gap={16}>
                        <Icons.Instagram/>
                        <Icons.Whatsapp/>
                        <Icons.Linkedin/>
                        <Icons.Twitter/>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    )
}


const Product = () => {


    return (
        <Flex vertical gap={spacing("3xl")}>
            <div className="footer__product__title">Products</div>
            <Flex vertical gap={16} className="footer__product__items">
                {products.items.map(it => {
                    return <div className="footer__product__item__link" onClick={() => window.open(it.link, "_blank")}>{it.title}</div>
                })}
            </Flex>
        </Flex>
    )
}

const ContactUs = () => {
    return (
        <Flex vertical gap={spacing("3xl")}>
            <div className="footer__contactus__title">Contact us</div>
            <Flex vertical gap={16} className="footer__contactus__items">
                {contactus.items.map(it => {
                    return <div>{it}</div>
                })}
            </Flex>
        </Flex>
    )
}

const Address = () => {
    return (
        <Flex vertical gap={spacing("3xl")}>
            <div className="footer__address__title">Address</div>
            <Flex vertical className="footer__address__items">
                {address.items.map(it => {
                    return <div>{it}</div>
                })}
            </Flex>
        </Flex>
    )
}