import datasource from "../../../datasource"
import Flex from "../../../components/Flex"
import { spacing } from "../styles"

import "./layout.scss";
import MobileIcon from "../../../components/MobileIcon";
import { useNavigate } from "react-router-dom";
import useNav from "../../../components/useNav";

const {
    company,
    products,
    contactus,
    address
} = datasource.footer


export default () => {

    return (
        <div className="mb-layout__footer">
            <Flex vertical gap={spacing("6xl")}>
                <Logo></Logo>
                <Flex className="mb-footer__items">
                    <Flex vertical gap={spacing("4xl")}>
                        <Product></Product>
                        <ContactUs></ContactUs>
                    </Flex>
                    <Address></Address>
                </Flex>
                <div className="mb-footer__company-box">
                    <Flex vertical className="mb-footer__company" gap={spacing("3xl")}>
                        <Flex gap={spacing("3xl")}>
                            <MobileIcon.Instagram width={24} height={24}/>
                            <MobileIcon.Whatsapp width={24} height={24}/>
                            <MobileIcon.Linkedin width={24} height={24}/>
                            <MobileIcon.Twitter width={24} height={24}/>
                        </Flex>
                        {company.title}
                    </Flex>
                </div>
            </Flex>
        </div>
    )
}

const Logo = () => {

    const {nav} = useNav();

    return (
        <Flex className="mb-footer__logo">
            <Flex gap={spacing("md")} align="center">
                <MobileIcon.LogoDark width={32} height={32} onClick={() => nav("/")}></MobileIcon.LogoDark>
                Roundblock
            </Flex>
        </Flex>
    )
}


const Product = () => {
    return (
        <Flex vertical gap={16}>
            <div className="mb-footer__product__title">Products</div>
            <Flex vertical gap={spacing("lg")} className="mb-footer__product__items">
                {products.items.map(it => {
                    return <div className="mb-footer__product__item__link" onClick={() => window.open(it.link, "_blank")}>{it.title}</div>
                })}
            </Flex>
        </Flex>
    )
}

const ContactUs = () => {
    return (
        <Flex vertical gap={16}>
            <div className="mb-footer__contactus__title">Contact us</div>
            <Flex vertical gap={spacing("lg")} className="mb-footer__contactus__items">
                {contactus.items.map(it => {
                    return <div>{it}</div>
                })}
            </Flex>
        </Flex>
    )
}

const Address = () => {
    return (
        <Flex vertical gap={16}>
            <div className="mb-footer__address__title">Address</div>
            <Flex vertical className="mb-footer__address__items">
                {address.items.map(it => {
                    return <div>{it}</div>
                })}
            </Flex>
        </Flex>
    )
}