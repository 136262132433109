import { ReactNode, useState } from "react"
import Icons, { Icon } from "../../../components/Icons"

import "./notification.scss";
import Flex from "../../../components/Flex";

interface Content {
    icon: React.JSX.Element
    text: string
    type: string
}

export default () => {

    const [content, setContent] = useState<Content>()
    const [show, setShow] = useState<boolean>(() => false)
    const [scheduleId, setScheduleId] = useState<any>();

    const success = (message: string) => {
        scheduleId && clearTimeout(scheduleId);
        setContent({
            icon: <Icons.Success width={20} height={20}/>,
            text: message,
            type: "success"
        })
        setShow(true)
        setScheduleId(
            setTimeout(() => {
                setShow(false)
            }, 2000)
        )
    }

    const error = (message: string) => {
        scheduleId && clearTimeout(scheduleId);
        setContent({
            icon: <Icons.Fail width={20} height={20}/>,
            text: message,
            type: "error"
        })
        setShow(true)
        setScheduleId(
            setTimeout(() => {
                setShow(false)
            }, 2000)
        )
    }

    const render = (
        <div className={["notification-box", show?"notification-box_show": ""].join(" ")}>
            <Flex
                className={["notification", `notification_${content?.type}`].join(" ")}
                gap={12}
                align="center"
            >
                {content?.icon}
                <div className="notification__text">{content?.text}</div>
                <div className="notification__close">
                    <Icons.NotificationClose onClick={() => setShow(false)}/>
                </div>
            </Flex>
        </div>
    )


    return {
        render,
        success,
        error
    }

}