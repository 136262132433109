import { ReactNode, useState } from "react"
import Icons, { Icon } from "../../../components/Icons"

import "./notification.scss";
import Flex from "../../../components/Flex";

interface Content {
    icon: React.JSX.Element
    text: string
    type: string
}

export default () => {

    const [content, setContent] = useState<Content>()
    const [show, setShow] = useState<boolean>(() => false)
    const [scheduleId, setScheduleId] = useState<any>();

    const success = (message: string) => {
        scheduleId && clearTimeout(scheduleId);
        setContent({
            icon: <Icons.Success width={20} height={20}/>,
            text: message,
            type: "success"
        })
        setShow(true)
        setScheduleId(
            setTimeout(() => {
                setShow(false)
            }, 2000)
        )
    }

    const error = (message: string) => {
        scheduleId && clearTimeout(scheduleId);
        setContent({
            icon: <Icons.Fail width={20} height={20}/>,
            text: message,
            type: "error"
        })
        setShow(true)
        setScheduleId(
            setTimeout(() => {
                setShow(false)
            }, 2000)
        )
    }

    const render = (
        <div className={["mb-notification-box", show?"mb-notification-box_show": ""].join(" ")}>
            <Flex
                vertical
                className={["mb-notification", `mb-notification_${content?.type}`].join(" ")}
                gap={12}
                
            >
                <Flex justify="space-between" style={{width: "100%"}}>
                    {content?.icon}
                    <div className="mb-notification__close">
                        <Icons.NotificationClose onClick={() => setShow(false)}/>
                    </div>
                </Flex>
                <div className="mb-notification__text">{content?.text}</div>
            </Flex>
        </div>
    )


    return {
        render,
        success,
        error
    }

}