import { useEffect, useState } from 'react';
import { MenuContext } from '../contexts/MenuContext';
import {
  Outlet,
  useLocation,
} from "react-router-dom";
import { calCurrentMenu } from '../desktop/pages/layout/Header';
import MobileHeader from './pages/layout/MobileHeader';
import MobileFooter from './pages/layout/MobileFooter';

export default () => {

  //Menu operation
  const [currentMenu, setCurrentMenu] = useState<number[]>(() => [0])
  const changeCurrentMenu = (idx: number[]) => {
    setCurrentMenu(idx)
  }

  let location = useLocation();
  
  useEffect(() => {

    if(location){
      const menuPos = calCurrentMenu(location)
      setCurrentMenu(menuPos)
    }

  }, [location])

  return (
     <MenuContext.Provider value={{current: currentMenu, setCurrent: changeCurrentMenu}}>
        <>
          <MobileHeader/>
          <Outlet/>
          <MobileFooter/>
        </>
     </MenuContext.Provider>
  );
}
