

import arrowUpSvg from "../assets/images/arrowup@3x.png"
import chevronDown from "../assets/images/chevron_down@3x.png"
import chevronUp from "../assets/images/chevron_up@3x.png"
import mobileMenuBtnClosed from "../assets/images/mobile_menu_btn_closed@3x.png"
import mobileMenuBtnOpened from "../assets/images/mobile_menu_btn_opened@3x.png"
import success from "../assets/images/success@3x.png"
import notificationClose from "../assets/images/notification_close@3x.png"
import instagram from "../assets/images/instagram@3x.png"
import linkedin from "../assets/images/linkedin@3x.png"
import whatsapp from "../assets/images/whatsapp@3x.png"
import twitter from "../assets/images/twitter@3x.png"
import productB2b from "../assets/images/products_b2b@3x.png"
import productGpt from "../assets/images/products_gpt@3x.png"
import productOfferEasy from "../assets/images/products_offereasy@3x.png"


import specSvg0 from "../assets/images/spec1@3x.png"
import specSvg1 from "../assets/images/spec2@3x.png"
import specSvg2 from "../assets/images/spec3@3x.png"

// import logo from "../assets/images/logo.svg"
import mobileLogoDark from "../assets/images/logo_mobile_dark@3x.png"
import mobileLogo from "../assets/images/logo_mobile@3x.png"



import { CSSProperties } from "react"

type IconSize = {
    width?: number
    height?: number
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

type IconProps = {
    src: string
} & IconSize 

export const ArrowUp = (props: IconSize) => {
    return (<Icon src={arrowUpSvg} {...props}></Icon>)
}

export const Spec = (props: {idx: number} & IconSize) => {
    return props.idx==0?<Spec0 {...props}/>:props.idx==1?<Spec1 {...props}/>:<Spec2 {...props}/>
}

export const Spec0 = (props: IconSize) => {
    return (<Icon src={specSvg0} {...props}></Icon>)
}

export const Spec1 = (props: IconSize) => {
    return (<Icon src={specSvg1} {...props}></Icon>)
}

export const Spec2 = (props: IconSize) => {
    return (<Icon src={specSvg2} {...props}></Icon>)
}

export const ProductB2b = (props: IconSize) => {
    return (<Icon src={productB2b} {...props}></Icon>)
}

export const ProductGpt = (props: IconSize) => {
    return (<Icon src={productGpt} {...props}></Icon>)
}

export const ProductOfferEasy = (props: IconSize) => {
    return (<Icon src={productOfferEasy} {...props}></Icon>)
}

export const Logo = (props: IconSize) => {
    return (<Icon src={mobileLogoDark} {...props}></Icon>)
}

export const LogoDark = (props: IconSize) => {
    return (<Icon src={mobileLogoDark} {...props}></Icon>)
}

export const Instagram = (props: IconSize) => {
    return (<Icon src={instagram} {...props}></Icon>)
}

export const Whatsapp = (props: IconSize) => {
    return (<Icon src={whatsapp} {...props}></Icon>)
}

export const Linkedin = (props: IconSize) => {
    return (<Icon src={linkedin} {...props}></Icon>)
}

export const Twitter = (props: IconSize) => {
    return (<Icon src={twitter} {...props}></Icon>)
}

export const MobileLogo = (props: IconSize) => {
    return (<Icon src={mobileLogoDark} {...props}></Icon>)
}

export const MobileMenuBtnClosed = (props: IconSize) => {
    return (<Icon src={mobileMenuBtnClosed} {...props}></Icon>)
}

export const MobileMenuBtnOpened = (props: IconSize) => {
    return (<Icon src={mobileMenuBtnOpened} {...props}></Icon>)
}

export const ChevronDown = (props: IconSize) => {
    return (<Icon src={chevronDown} {...props}></Icon>)
}

export const ChevronUp = (props: IconSize) => {
    return (<Icon src={chevronUp} {...props}></Icon>)
}

export const Success = (props: IconSize) => {
    return (<Icon src={success} {...props}></Icon>)
}

export const NotificationClose = (props: IconSize) => {
    return (<Icon src={notificationClose} {...props}></Icon>)
}

export const Icon = (props: IconProps) => {

    // console.log(`Icon props: ${JSON.stringify(props)}`)

    const styles: CSSProperties = {
        width: props.width + "px",
        height: props.height + "px"
    }

    return (<img {...props} src={props.src} style={styles}></img>)

}

export default {
    ArrowUp,
    Spec,
    ProductB2b,
    ProductGpt,
    ProductOfferEasy,
    Logo,
    LogoDark,
    Instagram,
    Whatsapp,
    Linkedin,
    Twitter,
    MobileLogo,
    MobileMenuBtnClosed,
    MobileMenuBtnOpened,
    ChevronDown,
    ChevronUp,
    Success,
    NotificationClose
}