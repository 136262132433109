import { useEffect, useState } from 'react';
import { MenuContext } from '../contexts/MenuContext';
import {
  Outlet,
  useLocation,
} from "react-router-dom";
import Header, { calCurrentMenu } from './pages/layout/Header';
import Footer from './pages/layout/Footer';

function App() {

  //Menu operation
  const [currentMenu, setCurrentMenu] = useState<number[]>(() => [0])
  const changeCurrentMenu = (idx: number[]) => {
    setCurrentMenu(idx)
  }

  let location = useLocation();
  
  useEffect(() => {

    if(location){
      const menuPos = calCurrentMenu(location)
      setCurrentMenu(menuPos)
    }

  }, [location])

  return (
     <MenuContext.Provider value={{current: currentMenu, setCurrent: changeCurrentMenu}}>
        <>
          <Header></Header>
          <Outlet/>
          <Footer></Footer>
        </>
     </MenuContext.Provider>
  );
}

export default App;
