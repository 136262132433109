import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './desktop/App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from './desktop/pages/HomePage';
import Career from './desktop/pages/Career';
import Products from './desktop/pages/Products';

import MobileApp from './mobile/MobileApp';
import MobileHomePage from './mobile/pages/MobileHomePage';
import MobileCareer from './mobile/pages/MobileCareer';
import MobileProducts from './mobile/pages/MobileProducts';



const landscape = () => {
  const screenHeight = window.innerHeight
  const screenWidth = window.innerWidth
  return screenWidth / screenHeight > 1
}
const root = ReactDOM.createRoot(document.getElementById('root'));
if(landscape()){
  const desktopRouter = createBrowserRouter([
    {
      element: <App></App>,
      children: [
        {
          path: "/",
          element: <HomePage/>,
        },
        {
          path: "products",
          element: (<Products/>),
        },
        {
          path: "careers",
          element: (<Career/>),
        }
      ]
    },
    
  ]);
  root.render(
    <React.StrictMode>
      <RouterProvider router={desktopRouter} />
    </React.StrictMode>
  );
}else{
  const mobileRouter = createBrowserRouter([
    {
      element: <MobileApp></MobileApp>,
      children: [
        {
          path: "/",
          element: <MobileHomePage/>,
        },
        {
          path: "products",
          element: (<MobileProducts/>),
        },
        {
          path: "careers",
          element: (<MobileCareer/>),
        }
      ]
    },
    
  ]);
  root.render(
    <React.StrictMode>
      <RouterProvider router={mobileRouter} />
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
