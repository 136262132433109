import React from "react"

export interface FormItemError{
    msg?: string,
    show?: boolean
}

export interface FormContextType {
    setFieldValue: (fieldName: string, value: any) => void
    setFieldError: (fieldName: string, msg?: FormItemError) => void
    setFieldSuccess: (fieldName: string) => void
    clear: () => void
    data: Record<string, any>
    error: Record<string, any>
}

export interface FormItemContextType {
    name: string
    setFieldValue: ( value: any) => void
    fieldValue: any
    fieldError: any
}

export const FormContext = React.createContext<FormContextType>({
    data: {},
    error: {},
    setFieldValue: function (fieldName: string, value: any): void {
        throw new Error("Function not implemented.")
    },
    setFieldError: function (fieldName: string, msg?: FormItemError | undefined): void {
        throw new Error("Function not implemented.")
    },
    setFieldSuccess: function (fieldName: string): void {
        throw new Error("Function not implemented.")
    },
    clear: function (): void {
        throw new Error("Function not implemented.")
    },
})
export const FormItemContext = React.createContext<FormItemContextType>({
    name: "",
    fieldValue: undefined,
    fieldError: undefined,
    setFieldValue: function (value: any): void {
        throw new Error("Function not implemented.")
    }
})