import { ReactNode } from "react"

import "./Button.scss"

type ButtonProps = {
    
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default (props: ButtonProps) => {


    return (
        <div className="mb-rb-inline-block">
            <div {...props} className={["mb-rb-button", props.disabled?'mb-rb-button_disabled':'', props.className].join(" ")} >
                {props.children}
            </div>
        </div>
        
    )
}